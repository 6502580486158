import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Services from "../components/Services"

const ServicePage = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: markdownRemark(frontmatter: { id: { eq: "services" } }) {
        ...SEO
        frontmatter {
          title
        }
      }
    }
  `)

  const {
    frontmatter: { title, ogImage },
  } = data

  return (
    <>
      <SEO title={title} ogImage={ogImage} />

      <Layout>
        <div className="c-page-title">
          <div className="u-inner">
            <h1>{title}</h1>
            <Services />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ServicePage
